import _string from "../../actual/string";
import _es from "../../modules/es.weak-map";
import _esnextString from "../../modules/esnext.string.at";
import _esnextString2 from "../../modules/esnext.string.cooked";
import _esnextString3 from "../../modules/esnext.string.code-points";
import _esnextString4 from "../../modules/esnext.string.dedent";
import _esnextString5 from "../../modules/esnext.string.match-all";
import _esnextString6 from "../../modules/esnext.string.replace-all";
var exports = {};
var parent = _string;
_es;
// TODO: remove from `core-js@4`
_esnextString;
_esnextString2;
_esnextString3;
_esnextString4;
_esnextString5;
_esnextString6;
exports = parent;
export default exports;